import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import MMTCloud from "../../../api/MMTCloud";
import NiceModal from "../../modals/NiceModal";
import ClientForm, {Button} from "../forms/ClientForm";
import toast from "react-hot-toast";

import Spin from 'arui-feather/spin'
import ls from 'local-storage'

import pencil from '../../../assets/images/gear_p.png'
import trash from '../../../assets/images/trash_p.png'
import developer from '../../../assets/images/developer.png'
import Tabs, {TabItem} from "../../ui/Tabs";

export default function AllClientsPanel(props) {
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editId, setEditId] = useState(undefined);
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);

    const [memorizeData, setMemorizeData] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        MMTCloud.getInstance().getOauthClients().then(arr => {
            setApps(arr);
            setLoading(false);
        });
    }, []);

    const editItem = apps.filter(x => (x._id == editId))[0];

    if (loading == false && apps.length == 0 && addModalVisible == false){
        return (
            <VoidWrapper>
                <div>
                    <VoidImg src={developer} />
                </div>
                <div>
                    You don't have any apps created.
                </div>
                <div style={{marginTop: 30}} >
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }} >
                        Create first app
                    </Button>
                </div>
            </VoidWrapper>
        )
    }

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Add client
                </Button>
            </TopPlaceholder>

            {apps.map((a, i) => {
                return (
                    <AppItem key={a._id} onClick={() => {

                    }}>
                        <LeftItem>
                            <AppName>
                                {a.name}
                            </AppName>
                            <ParamRow>
                                <ParamLabel>
                                    {'client_id:'}
                                </ParamLabel>
                                <ParamValue>
                                    {a._id}
                                </ParamValue>
                            </ParamRow>
                            <ParamRow>
                                <ParamLabel>
                                    {'client_secret:'}
                                </ParamLabel>
                                <ParamValue>
                                    {ls(`app_secret_${a._id}`) || a.client_secret}
                                </ParamValue>
                            </ParamRow>
                            <ParamRow>
                                <ParamLabel>
                                    {'redirect uri:'}
                                </ParamLabel>
                                <ParamValue>
                                    {a.redirect}
                                </ParamValue>
                            </ParamRow>
                        </LeftItem>
                        <RightItem>

                            <EditImage
                                src={pencil}
                                onClick={() => {
                                    setEditId(a._id);
                                }}/>

                            <EditImage
                                style={{marginLeft: 20}}
                                src={trash}
                                onClick={async () => {
                                    if (loading == true) {
                                        return;
                                    }
                                    if (window.confirm('Are you sure you want to delete this app?') == false) {
                                        return;
                                    }
                                    setLoading(true);
                                    await MMTCloud.getInstance().deleteOauthClient(a?._id);
                                    let arr = await MMTCloud.getInstance().getOauthClients();
                                    setApps(arr);
                                    setLoading(false);
                                    setEditId(undefined);
                                    toast('Deleted!');
                                }}/>
                        </RightItem>
                    </AppItem>
                )
            })}

            {editItem == undefined ? null :
                <NiceModal onClose={() => {
                    setEditId(undefined);
                }}>

                    <InnerPlaceholder>
                        <h2>
                            {editItem?.name}
                        </h2>

                        <InnerContent>
                            <ClientForm
                                loading={loading}
                                {...editItem}
                                onSave={async d => {
                                    setLoading(true);
                                    await MMTCloud.getInstance().updateOauthClient(editItem?._id, d);
                                    let arr = await MMTCloud.getInstance().getOauthClients();
                                    setApps(arr);
                                    setLoading(false);
                                    setEditId(undefined);
                                    toast('Saved!');
                                }}
                            />
                        </InnerContent>

                    </InnerPlaceholder>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <h2>
                            {'Create new app'}
                        </h2>

                        <InnerContent>
                            <ClientForm
                                loading={loading}
                                onSave={async d => {
                                    setLoading(true);
                                    let pld = await MMTCloud.getInstance().createOauthClient(d);
                                    let {_id, secret} = pld;
                                    ls(`app_secret_${_id}`, secret);
                                    let arr = await MMTCloud.getInstance().getOauthClients();
                                    setApps(arr);
                                    setLoading(false);
                                    setAddModalVisible(false);
                                    toast('Saved!');
                                    setMemorizeData(pld);
                                }}
                            />
                        </InnerContent>

                    </InnerPlaceholder>

                </NiceModal>
            }

            {memorizeData == undefined ? null :
                <NiceModal onClose={() => {
                    setMemorizeData(undefined);
                }}>

                    <InnerPlaceholder>
                        <h2>
                            {'App credentials'}
                        </h2>

                        <p>
                            Please save these credentials in your app config.
                        </p>

                        <p>
                            {`Client ID: ${memorizeData?._id}`}
                        </p>

                        <p>
                            {`Client Secret: ${memorizeData?.secret}`}
                        </p>


                    </InnerPlaceholder>

                </NiceModal>
            }



        </Wrapper>
    );
}

const VoidWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const VoidImg = styled.img`
  height: 400px;
  
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ParamRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const ParamLabel = styled.div`
  font-weight: bold;
  opacity: 0.5;
`;

const ParamValue = styled.div`
  margin-left: 10px;
`;

const AppName = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
`;

const LeftItem = styled.div`
  flex: 1;
`;

const RightItem = styled.div`
  margin-left: 20px;
`;

const EditSpan = styled.span`
  cursor: pointer;
`;

const InnerContent = styled.div`

`;

const Wrapper = styled.div`

`;

const InnerPlaceholder = styled.div`
  width: 520px;
`;

const AppItem = styled.div`
  padding: 10px;
  border: 1px solid whitesmoke;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
`;

const EditImage = styled.img`
  height: 24px;
  cursor: pointer;
`;