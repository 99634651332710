import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

export default function ClientForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [privacy_policy_url, setPrivacy_policy_url] = useState(props.privacy_policy_url || '');
    const [name, setName] = useState(props.name || '');
    const [redirect, setRedirect] = useState(props.redirect || '');

    return (
        <Wrapper>

            <Row>
                <Label>
                    Name
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }} />
            </Row>

            <Row>
                <Label>
                    Redirect URI
                </Label>
                <Input value={redirect} onChange={evt => {
                    setRedirect(evt.target.value);
                }} />
            </Row>

            <Row>
                <Label>
                    Privacy Policy URL
                </Label>
                <Input value={privacy_policy_url} onChange={evt => {
                    setPrivacy_policy_url(evt.target.value);
                }} />
            </Row>

            <div>
                <Button onClick={() => {
                    if (loading == true){
                        return;
                    }
                    if (isValidUrl(redirect) == false){
                        return window.alert('Redirect URI is not valid');
                    }
                    onSave({
                        name,
                        redirect,
                        privacy_policy_url
                    });
                }} >
                    <Spin visible={loading} />
                    {loading == true ? null : 'Save'}
                </Button>
            </div>

        </Wrapper>
    );
}


export const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        console.error(e);
        return false;
    }
    return true;
};

export const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  height: 52px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;

  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }
`;


const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  opacity: 0.7;
`;