import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import {useDispatch, useMappedState} from "redux-react-hook";
import DoctorTemplate from "../templates/DoctorTemplate";
import AllClientsPanel from "../oauth/panels/AllClientsPanel";

function IndexApp(props) {

    let {loading} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading
        }
    }, []));

    const dispatch = useDispatch();
    const [error, setError] = useState(undefined);

    return (

        <DoctorTemplate>


            <AllClientsPanel/>



        </DoctorTemplate>

    );
}

const Wrapper = styled.div`
    
`;

export default IndexApp
