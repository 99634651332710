import logo from './logo.svg';
import './App.css';

import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger'
import {default as ReduxThunk} from 'redux-thunk';
import {reducer} from './redux/reducers'
import {StoreContext} from 'redux-react-hook';

import RouterApp from "./components/apps/RouterApp";

const store = createStore(
    reducer,
    undefined,
    compose(
        applyMiddleware(ReduxThunk), applyMiddleware(logger)
    )
)

function App() {

  return (
      <StoreContext.Provider value={store}>
        <RouterApp/>
      </StoreContext.Provider>
  );
}

export default App;
