import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import StyledLoginForm from "../auth/forms/StyledLoginForm";

import * as actions from '../../redux/actions/AuthActions'
import {useDispatch, useMappedState} from "redux-react-hook";
import NiceModal from "../modals/NiceModal";

import doctorsImg from '../../assets/images/doctors_login_gr3.jpg'
import runningImg from '../../assets/images/cardiomood_teams_.jpg'
import template_logo from '../../assets/images/cm_new_logo.png'
import cm_dev_logo from '../../assets/images/cm_dev.jpg'


function LoginApp(props) {

    let {loading} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading
        }
    }, []));

    const dispatch = useDispatch();
    const [error, setError] = useState(undefined);
    let isCM = (window.location.href.indexOf('cardiomood') > -1);

    return (

        <Wrapper>
            <InnerWrapper2Sides>

                <Left>
                    <LeftBackgroundImage src={cm_dev_logo}/>
                </Left>

                <Right>

                    <TopRightPlaceholder>

                    </TopRightPlaceholder>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        {/*<ClinicLogo src={chariteImg} style={{marginBottom: 40}}/>*/}
                        {/*<ClinicLogo src={corsanoImg} style={{marginBottom: 40}}/>*/}
                        <ClinicLogo src={template_logo} style={{marginBottom: 40}}/>

                        <StyledLoginForm loading={loading} onSubmit={async d => {
                            if (loading == true) {
                                return;
                            }
                            let pld = await dispatch(actions.logIn(d));
                            if (pld.error != undefined) {
                                setError('Can not sign in. Please check your login or password.')
                            }
                            console.log('pld => pld = ', pld);
                            // window.location.reload();
                        }}/>
                    </div>

                    <BottomRightPlaceholder>
                        {/*<BottomImg src={corsanoImg}/>*/}
                    </BottomRightPlaceholder>

                </Right>

            </InnerWrapper2Sides>

            {error == undefined ? null :
                <NiceModal onClose={() => {
                    setError(undefined);
                }}>
                    <ErrorPlaceholder>
                        {error}
                    </ErrorPlaceholder>
                </NiceModal>
            }


        </Wrapper>

    );
}

const BottomImg = styled.img`
  height: 25px;
`;

const ClinicLogo = styled.img`
  //height: 95px;
  height: 45px;
`;

const BottomRightPlaceholder = styled.div`
  padding-bottom: 20px;
  opacity: 0.9;
`;

const TopRightPlaceholder = styled.div`
  padding-top: 10px;
`;

const ErrorPlaceholder = styled.div`
  padding: 40px;
  color: red;
`;

const Left = styled.div`
  flex: 1;
  background: white;
`;

const LeftBackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url('${props => props.src}');
`;

const Right = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFB;
  @media (max-width: 920px) {
    width: 60vw;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const InnerWrapper2Sides = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100vw;
`;

const WrapperInner = styled.div`
  background: #FFFFFF;
  box-shadow: 6px 40px 80px rgba(0, 114, 188, 0.15);
  border-radius: 18px;
  border: 2px solid rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 35px;
  padding-left: 47px;
  padding-right: 47px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default LoginApp
