import axios from 'axios'
import * as ls from 'local-storage'
import jwt_decode from "jwt-decode";
import env from "react-dotenv";
import {HealthCloudUser, UsersCloudUser, AuthError} from "./types/api";

const {USERS_API_ENDPOINT, HEALTH_API_ENDPOINT} = env;

export const delay = (t: number) => new Promise(f => setTimeout(f, t));

class MMTCloud {

    private static instance: MMTCloud;

    private _usersApiAxios: any;
    private _healthApiAxios: any;

    usersTokenTTL?: number;
    usersRefreshTTL?: number;
    usersToken?: string;
    usersCloudUser?: UsersCloudUser;

    healthTokenTTl?: number;
    healthRefreshTTL?: number;
    healthToken?: string;
    healthCloudUser?: HealthCloudUser;

    currentUserObject?: {
        healthUser?: HealthCloudUser,
        usersUser?: UsersCloudUser,
        goals: Array<object>
    }

    constructor() {
        let lsUsersToken = ls.get<string>('users_cloud_token');
        let lsHealthToken = ls.get<string>('health_cloud_token');
        try {
            let d1 = jwt_decode(lsUsersToken);
            let d2 = jwt_decode(lsUsersToken);
            console.log('d1, d2 = ', d1, d2);
            this.usersToken = lsUsersToken;
            this.healthToken = lsHealthToken;
        } catch (exc: any) {

        }
        console.log('USERS_API_ENDPOINT = ', USERS_API_ENDPOINT);
        this._usersApiAxios = axios.create({
            baseURL: USERS_API_ENDPOINT,
            timeout: 2 * 60 * 1000
        });
        this._healthApiAxios = axios.create({
            baseURL: HEALTH_API_ENDPOINT,
            timeout: 2 * 60 * 1000
        });
    }

    public get usersApiAxios() {
        let ax = this._usersApiAxios;
        // ax.defaultConfig.headers.authorization = `Bearer ${this.usersToken}`;
        Object.assign(ax.defaults, {headers: {authorization: `Bearer ${this.usersToken}`}});
        return ax;
    }

    public get healthApiAxios() {
        let ax = this._healthApiAxios;
        Object.assign(ax.defaults, {headers: {authorization: `Bearer ${this.healthToken}`}});
        return ax;
    }

    public getUUID(): string | null | undefined {
        let obj = this.currentUserObject;
        if (obj == undefined) {
            return null;
        }
        let uu = obj.usersUser;
        return uu?.uuid;
    }

    public getUsersCloudUser(): UsersCloudUser | null | undefined {
        let obj = this.currentUserObject;
        if (obj == undefined) {
            return null;
        }
        return obj.usersUser;
    }

    public getHealthCloudUser(): HealthCloudUser | null | undefined {
        let obj = this.currentUserObject;
        if (obj == undefined) {
            return null;
        }
        return obj.healthUser;
    }

    public static getInstance(): MMTCloud {
        if (!MMTCloud.instance) {
            MMTCloud.instance = new MMTCloud();
        }
        return MMTCloud.instance;
    }

    private async refreshUsersCloudToken() {
        if (this.usersToken == undefined) {
            return;
        }
        const a = MMTCloud.getInstance()._usersApiAxios;
        try {
            this.usersToken = (await a.get('/auth/refresh', {
                headers: {
                    Authorization: `Bearer ${this.usersToken}`
                }
            })).data.token;
            ls.set('users_cloud_token', this.usersToken);
            console.log('users token refreshed');
        } catch (exc) {

        }
    }

    private async refreshHealthCloudToken() {
        if (this.healthToken == undefined) {
            return;
        }
        const a = MMTCloud.getInstance()._healthApiAxios;
        try {
            this.healthToken = (await a.get('/auth/refresh', {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                }
            })).data.token
            ls.set('health_cloud_token', this.healthToken);
            console.log('health token refreshed');
        } catch (exc: any) {

        }
    }

    public async refreshTokens() {
        try {
            console.log('refreshTokens occured!');
            await this.refreshUsersCloudToken();
            await this.refreshHealthCloudToken();
        } catch (exc: any) {

        }
    }

    private async autoRefreshTokens() {
        while (true) {
            try {
                await this.refreshTokens();
                await delay(5 * 60 * 1000);
            } catch (exc) {

            }

        }
    }

    public async getLazyMe() {

    }

    public async logout() {
        ls.remove('users_cloud_token');
        ls.remove('health_cloud_token');
        this.usersToken = undefined;
        this.healthToken = undefined;
        this.currentUserObject = undefined;
    }

    public async getMe() {
        await this.refreshTokens();
        if (this.healthToken == undefined || this.usersToken == undefined) {
            return null;
        }
        const a = MMTCloud.getInstance()._usersApiAxios;
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await a.get('/profile', {
                headers: {
                    Authorization: `Bearer ${this.usersToken}`
                }
            })).data;
            let usersUser: UsersCloudUser = pld;
            let hPld = (await h.get('/profile', {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                }
            })).data;
            let healthUser: HealthCloudUser = hPld;
            // await this.refreshTokens();
            let goals = await this.getGoals();
            this.currentUserObject = {
                healthUser: healthUser,
                usersUser: usersUser,
                goals: goals
            }
            return this.currentUserObject;
        } catch (exc: any) {
            ls.remove('users_cloud_token');
            ls.remove('health_cloud_token');
            return null;
        }
    }


    public async getGoals() {
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await h.get('/goals', {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                },
                params: {
                    per_page: 1000
                }
            })).data.data;
            return pld;
        } catch (exc) {

        }
    }

    public async getOauthClients() {
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await h.get('/oauth/clients', {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                },
                params: {
                    per_page: 1000
                }
            })).data.data;
            return pld;
        } catch (exc) {

        }
    }

    public async createOauthClient(d: any) {
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await h.post('/oauth/clients', d, {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                }
            })).data;
            return pld;
        } catch (exc) {

        }
    }

    public async updateOauthClient(id: string, d: any) {
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await h.put(`/oauth/clients/${id}`, d, {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                }
            })).data;
            return pld;
        } catch (exc) {

        }
    }

    public async deleteOauthClient(id: string) {
        const h = MMTCloud.getInstance()._healthApiAxios;
        try {
            let pld = (await h.delete(`/oauth/clients/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.healthToken}`
                }
            })).data;
            return pld;
        } catch (exc) {

        }
    }

    public async checkLoginAndPassword(email: string, password: string) {
        const a = MMTCloud.getInstance()._usersApiAxios;
        try {
            let pld_ = (await a.post(`/login`, {
                email: email,
                password: password
            })).data;
            let {token} = pld_;
            let pld = (await a.get('/profile', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })).data;
            return pld;
        } catch (exc) {

        }
    }

    public async login(email: string, password: string) {
        const a = MMTCloud.getInstance()._usersApiAxios;
        const h = MMTCloud.getInstance()._healthApiAxios;
        ls.remove('users_cloud_token');
        ls.remove('health_cloud_token');
        try {
            let pld = (await a.post(`/login`, {
                email: email,
                password: password
            })).data;
            let {
                token,
                ttl,
                refresh_ttl,
                user
            }: { token: string; ttl: number; refresh_ttl: number; user: UsersCloudUser } = pld;
            this.usersTokenTTL = +ttl;
            this.usersToken = token;
            this.usersRefreshTTL = +refresh_ttl;
            this.usersCloudUser = user;
            ls.set('users_cloud_token', this.usersToken);
        } catch (exc: any) {
            console.log('exc.response = ', exc.response);
            let {status, data} = exc.response;
            let error: AuthError = {code: status, message: data.message};
            throw error;
        }
        try {
            let pld = (await h.post(`/login`, {
                user_api_token: this.usersToken
            })).data;
            let {
                token,
                ttl,
                refresh_ttl,
                user
            }: { token: string; ttl: number; refresh_ttl: number; user: HealthCloudUser } = pld;
            this.healthTokenTTl = +ttl;
            this.healthToken = token;
            this.healthRefreshTTL = +refresh_ttl;
            this.healthCloudUser = user;
            ls.set('health_cloud_token', this.healthToken);
        } catch (exc: any) {

        }
        let pld = await this.getMe();
        return pld;
    }

}

export default MMTCloud;
