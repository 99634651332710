import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';

import * as actions from '../../redux/actions/AuthActions'
import {Code} from 'react-content-loader'
import FullPagePreloader from "../preloader/FullPagePreloader";
import IndexApp from "./IndexApp";
import LoginApp from "./LoginApp";
import {Toaster} from "react-hot-toast";
import MMTCloud from "../../api/MMTCloud";


const userRoute = (
    <Switch>
        <Route component={IndexApp}/>
    </Switch>
);

const cmRoute = (
    <Switch>
        <Route component={IndexApp}/>
    </Switch>
);

const guestRoute = (
    <Switch>
        <Route component={LoginApp}/>
    </Switch>
);

export default function RouterApp(props) {
    const [settings, setSettings] = useState(undefined);
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    let {currentUserObj, loading, initialized, anyLoading} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            initialized: state.auth.initialized,
            loading: state.auth.loading,
            anyLoading: state.auth.loading
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.initializeAuthorization()).then((pld1) => {

        });
    }, []);

    useInterval(() => {
        if (initialized == false || currentUserObj == undefined || anyLoading == true) {
            return;
        }
        MMTCloud.getInstance().refreshTokens().then(x => {
            console.log('tokens have been refreshed');
        });
    }, [2 * 60 * 1000]);

    if (loading == true && initialized == false) {
        return <FullPagePreloader visible={true}/>
    }
    if (initialized == false) {
        return <FullPagePreloader visible={true}/>;
    }

    if (currentUserObj == undefined) {
        return (
            <HashRouter>
                <Wrapper>
                    {guestRoute}
                </Wrapper>
            </HashRouter>
        )
    }
    const {healthUser, usersUser} = currentUserObj;

    let {first_name, last_name, customer_code} = usersUser;
    // let isStudyUser = ((customer_code != undefined) && (customer_code.indexOf('study') > -1));
    // let route = (isStudyUser == true) ? studyRoute : userRoute;
    let route = userRoute;
    // if (settingsLoaded == true && settings == undefined) {
    //     return (
    //         <AccessDeniedApp user={usersUser} />
    //     )
    // }

    return (
        <HashRouter>
            <Wrapper>
                {route}
                <Toaster/>
            </Wrapper>
        </HashRouter>
    );
}

const Wrapper = styled.div`

`;

const CardWrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}