import * as types from '../ActionTypes'
import MMTCloud from '../../api/MMTCloud';

//LOGIN
let startLoggingIn = () => {
    return {
        type: types.LOGIN
    }
}
let onLoggedIn = (currentUserObj) => {
    return {
        type: types.LOGIN_SUCCESS,
        currentUserObj: currentUserObj
    }
}
let onLoginFailed = (error) => {
    return {
        type: types.LOGIN_FAIL,
        error: error
    }
}

//thunk
export function logIn(data) {
    console.log('dispatched logIn: data = ', data);
    return (dispatch, getState) => {
        dispatch(startLoggingIn());
        return MMTCloud.getInstance().login(data.email, data.password).then(
            userObj => dispatch(onLoggedIn(userObj)),
            error => dispatch(onLoginFailed(error))
        )
    }
}

//AUTH_INIT
let startAuthInit = () => {
    return {
        type: types.INITIALIZE_AUTH
    }
}
let authInitFailed = (err) => {
    return {
        type: types.INITIALIZE_AUTH_FAIL,
        error: err
    }
}
let authInitSuccess = (currentUserObj) => {
    return {
        type: types.INITIALIZE_AUTH_SUCCESS,
        currentUserObj: currentUserObj
    }
}

//thunk
export function initializeAuthorization() {
    return (dispatch, getState) => {
        dispatch(startAuthInit());
        return MMTCloud.getInstance().getMe().then(
            currentUserObj => dispatch(authInitSuccess(currentUserObj)),
            err => dispatch(authInitFailed())
        );
    }
}

// LOGOUT

let startLoggingOut = () => {
    return {
        type: types.LOGOUT
    }
}
let onLogOutSuccess = (currentUserObj) => {
    return {
        type: types.LOGOUT_SUCCESS,
        currentUserObj: currentUserObj
    }
}
let onLogOutFailed = (error) => {
    return {
        type: types.LOGOUT_FAIL,
        error: error
    }
}

export function logout() {
    return (dispatch, getState) => {
        dispatch(startLoggingOut());
        return MMTCloud.getInstance().logout().then(
            () => dispatch(onLogOutSuccess()),
            err => dispatch(onLogOutFailed())
        );
    }
}