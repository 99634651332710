import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import * as authActions from '../../redux/actions/AuthActions'
import voidUserImage from '../../assets/images/user_void_0.png'
import CommonHelper from "../../helpers/CommonHelper";

import cm_new_logo from '../../assets/images/cm_new_logo.png'


const LINKS = [
    {
        value: 'index',
        link: '/',
        label: 'Apps'
    }
];

export default function DoctorTemplate(props) {
    const {active = 'index'} = props;
    const ref = useRef();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const dispatch = useDispatch();

    let {currentUserObj} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading
        }
    }, []));

    useOnClickOutside(ref, () => {
        if (settingsOpen == true) {
            setSettingsOpen(false);
        }
    });

    if (currentUserObj == undefined) {
        return null;
    }
    const {healthUser, usersUser, dashboardSettings} = currentUserObj;
    if (healthUser == undefined || usersUser == undefined) {
        return null;
    }
    let {first_name, last_name, customer_code, email} = usersUser;
    let isStudyUser = ((customer_code != undefined) && (customer_code.indexOf('study') > -1));

    let uName = (first_name == undefined) ? email : `${first_name} ${last_name}`;
    if (isStudyUser == true) {
        if (email != undefined && email.indexOf('@') > -1) {
            uName = email.split('@')[0];
        }
    }
    let currentUUID = currentUserObj?.healthUser?.uuid;
    let links = LINKS;

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopInnerPlaceholder>

                    <TopLeftPlaceholder>

                        <Logo src={cm_new_logo} onClick={() => {
                            CommonHelper.linkTo('/');
                        }}/>

                        <TopLeftLinksPlaceholder>
                            {links.map((link, i) => {
                                let isSelected = (link.value == active);
                                return (
                                    <LinkItem key={i} selected={isSelected} onClick={() => {
                                        CommonHelper.linkTo(link.link);
                                    }}>
                                        {link.label}
                                    </LinkItem>
                                )
                            })}
                            <LinkItem onClick={() => {
                                window.open(`https://developer.cardiomood.com/docs/create-an-app`, '_blank').focus();
                            }} >
                                Docs
                            </LinkItem>
                        </TopLeftLinksPlaceholder>
                    </TopLeftPlaceholder>

                    <TopRightPlaceholder onClick={() => {
                        if (settingsOpen == false) {
                            setSettingsOpen(true);
                        }
                    }}>
                        <AvaCircle/>
                        <CurrentUserName>
                            {uName}
                            {(dashboardSettings == undefined || dashboardSettings.tag == undefined || dashboardSettings.tag == '') ? null :
                                <span>
                                    {`[${dashboardSettings.tag}]`}
                                </span>
                            }
                            {settingsOpen == false ? null :
                                <DropdownPlaceholder ref={ref}>
                                    <div style={{cursor: 'pointer'}} onClick={async () => {
                                        dispatch(authActions.logout());
                                        CommonHelper.linkTo('/');
                                        window.location.reload();
                                    }}>
                                        Sign out
                                    </div>

                                </DropdownPlaceholder>
                            }
                        </CurrentUserName>
                    </TopRightPlaceholder>
                </TopInnerPlaceholder>
            </TopPlaceholder>

            <ContentPlaceholder>
                {props.children}
            </ContentPlaceholder>

            <FooterPlaceholder>

                <FLeft>


                </FLeft>

                <FRight>

                </FRight>

            </FooterPlaceholder>

        </Wrapper>
    );
}

const FLeft = styled.div`

`;

const FRight = styled.div`

`;

const DebugSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  opacity: 0.5;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  :hover {
    opacity: 1;
  }
`;

const FooterPlaceholder = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 5px;
  }
`;

const DebugSwitcherPlaceholder = styled.div`

`;

const breakWidth = 1280;

const LinkItem = styled.div`
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  margin-right: 30px;
  color: #000F4B;
  font-size: 16px;
  line-height: 20px;
  border-bottom: 4px solid ${props => (props.selected == true ? ('red') : 'white')};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 20px;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const TopLinkItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  margin-left: 30px;
  margin-right: 30px;
  opacity: ${props => (props.selected == true ? 1 : 0.4)};
  color: white;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const selectedImageSize = 66;
const notSelectedImageSize = 60;

const ItemImage = styled.img`
  width: ${props => (props.selected == true ? selectedImageSize : notSelectedImageSize)}px;
  height: ${props => (props.selected == true ? selectedImageSize : notSelectedImageSize)}px;
  margin-bottom: 20px;
`;

const ItemText = styled.div`
  text-align: center;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const LinksInnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const LinksArea = styled.div`
  background: rgb(34, 42, 50);
  width: 100vw;
  padding: 20px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;


const Logo = styled.img`
  height: calc(100% - 28px);
  margin-right: 70px;
  cursor: pointer;
`;

const CurrentUserName = styled.div`
  position: relative;
`;

const DropdownPlaceholder = styled.div`
  position: absolute;
  top: 40px;
  padding: 20px;
  background: white;
  z-index: 2;
  box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
  border-radius: 4px;
  width: 160px;
  right: 0px;
  text-align: right;
`;

const AvaCircle = styled.div`
  border-radius: 1000px;
  background: lightgrey;
  box-sizing: border-box;
  border: 1px solid grey;
  height: 34px;
  width: 34px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  background-image: url(${props => (props.src == undefined ? voidUserImage : props.src)});
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  //
  //:hover {
  //  opacity: 0.5;
  //}
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background: rgb(250, 250, 252);
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  box-sizing: border-box;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const TopLeftLinksPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
`;

const TopInnerPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: ${breakWidth}px;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 60px;
  box-sizing: border-box;
  width: 100vw;
  background: white;
  box-sizing: border-box;
`;

function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}